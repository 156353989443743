<template>
  <div class="banner">
    <div>
      <h5 class="font20 mb20">PC首页轮播图</h5>
      <el-upload
        :action="imgUrl"
        accept="image/*"
        :headers="headersToken"
        :data="dataPc"
        :before-upload="beforeUpload"
        :on-preview="onRreview"
        list-type="picture-card"
        :on-success="onSuccess"
        :on-remove="onRemove"
        name="imgFile"
        :file-list="fileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <!-- 手机端 -->
    <div class="banner-mobile">
      <h5 class="font20 mb20 mt50">手机端首页轮播图</h5>
      <el-upload
        :action="imgUrl"
        accept="image/*"
        :headers="headersToken"
        :data="dataPcMobile"
        :before-upload="beforeUploadMobile"
        :on-preview="onRreviewMobile"
        list-type="picture-card"
        :on-success="onSuccessMobile"
        :on-remove="onRemoveMobile"
        name="imgFile"
        :file-list="fileListMobile"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisibleMobile">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getBanners, deleBanner } from "@/apis/banner";
import { apiAll } from "@/utils/utils";
export default {
  components: {},
  props: {},
  data() {
    return {
      // bannerImg: [], //* 轮播图列表
      imgUrl: "", // *上传图片的地址
      urlApi: "", // *回显的地址
      dialogVisible: false, // *图片预览框是否显示
      dialogVisibleMobile: false, // *图片预览框是否显示
      dialogImageUrl: "", // *图片预览框的图片地址
      fileList: [], //* 图片回显
      fileListMobile: [], //* 手机图片回显
      dataPc: {
        bannerType: 1,
      },
      dataPcMobile: {
        bannerType: 2,
      },
      headersToken: {}, // *上传图片的token
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.imgUrl = apiAll + "banner/addBanner"; // *上传图片的地址
    this.urlApi = apiAll + "images/"; // *回显的地址
    this.headersToken = {
      token: localStorage.getItem("token"),
    };
    this.getBanners();
  },
  methods: {
    // *获取banner图片
    getBanners() {
      getBanners({}).then((res) => {
        // *fileList回显PC端口图片
        this.fileList = res.data.data[0].banners.map((item) => {
          return {
            url: this.urlApi + item.filePath,
            id: item.id,
          };
        });
        // *手机端口图片
        this.fileListMobile = res.data.data[1].banners.map((item) => {
          return {
            url: this.urlApi + item.filePath,
            id: item.id,
          };
        });
      });
    },

    // *图片限制长宽
    beforeUpload(file) {
      let _this = this;
      if (file.type.indexOf("image") < 0) {
        _this.$message({
          message: `请上传图片文件`,
          type: "warning",
        });
        return false;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      const isSize = new Promise(function(resolve, reject) {
        let width = 1920;
        let height = 982;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message({
            message: `上传图片尺寸只能是1920*982!请重新选择!`,
            type: "warning",
            duration: 8000,
            showClose: true,
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    // *图片上传成功
    onSuccess() {
      this.$message({
        message: `上传成功`,
        type: "success",
      });
    },
    // *删除图片
    onRemove(file) {
      // *二次确认删除
      if (file.id) {
        deleBanner({ id: file.id }).then(() => {
          this.$message({
            message: `删除成功`,
            type: "success",
          });
        });
      }
    },
    // *图片是否可以预览
    onRreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // *手机端的图片设置********************************************
    // *图片限制长宽
    beforeUploadMobile(file) {
      let _this = this;
      if (file.type.indexOf("image") < 0) {
        _this.$message({
          message: `请上传图片文件`,
          type: "warning",
        });
        return false;
      }
      //   const isLt2M = file.size / 1024 / 1024 < 2;
      const isSize = new Promise(function(resolve, reject) {
        let width = 750;
        let height = 1282;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          _this.$message({
            message: `上传图片尺寸只能是750*1280!请重新选择!`,
            type: "warning",
            duration: 8000,
            showClose: true,
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
    // *图片上传成功
    onSuccessMobile() {
      this.$message({
        message: `上传成功`,
        type: "success",
      });
    },
    // *删除图片
    onRemoveMobile(file) {
      // *二次确认删除
      if (file.id) {
        deleBanner({ id: file.id }).then(() => {
          this.$message({
            message: `删除成功`,
            type: "success",
          });
        });
      }
    },
    // *图片是否可以预览
    onRreviewMobile(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleMobile = true;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 480px;
    height: 246px;
  }
  /deep/.el-upload--picture-card {
    width: 480px;
    height: 246px;
    line-height: 246px;
  }
  .banner-mobile{
    /deep/.el-upload-list--picture-card .el-upload-list__item {
      width: 250px;
      height: 427px;
    }
    /deep/.el-upload--picture-card {
      width: 250px;
      height: 427px;
      line-height: 427px;
    }
  }
}
</style>
