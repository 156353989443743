// 存放所有用户相关的接口函数
// import axios from "../utils/utils";
import axios from "../utils/request";

// *获取所有banner
export let getBanners = (params) => axios.post("banner/getBanners", params);

//*添加banner
export let addBanner = (params) => axios.post("banner/addBanner", params);

//*删除banner
export let deleBanner = (params) => axios.post("banner/removeBanner", params);
